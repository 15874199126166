.locationPickerContainer {
  width: 11rem;
  line-height: 40px;
}

.locationPickerSelect {
  width: 11rem;
  display: flex;
  flex-direction: column;

  label {
    font-size: 0.875rem;
  }
}
