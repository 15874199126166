
.banner {
  font-family: "Montserrat"; 
  font-weight: 500; 
  white-space: "pre-wrap";
  color: 'white';
  background-color: #E85757;
  padding: 16px 40px 16px 20px;
  padding-right: 60px;
  top: 0 !important;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 2;
  @media (min-width: 768px) {
    top: 0;
    height: 80px;
    gap: 14px;
    padding: 16px 40px 16px 20px;
    display: flex;
  }
  @media (min-width: 1024px) {
    top: 0;
    height: 60px;
    gap: 20px;
    padding: 5px;
    display: flex;
  }
}