.main {
  width: auto;
  max-width: 420px;
}
.form {
  padding: 25px 40px 40px;
  font-size: 1.1rem;
}

.custom-modal-title {
  font-size: 24px;
  font-weight: 600;
}

.icon {
  margin-right: 10px;
}
.filter {
  margin-left: calc((100vw - 960px) / 2);
}
.filterForm {
  background-color: #f2f2f2;
  padding: 50px;
  width: 960px;
  border-radius: 8px;
}
.box {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  font-size: 16px;
  overflow: auto;
}
.box input {
  width: 15px;
  height: 15px;
}
.allNotes {
  max-height: 360px;
}
.mobileBox {
  display: none;
}

.reason {
  font-size: 0.9rem;
}

.reason-select {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 1025px) {
  .filterForm {
    width: 85vw;
    max-height: 70vh;
    overflow-y: auto;
  }
  .filter {
    margin-left: calc((100vw - 85vw) / 2);
  }
  .allNotes {
    display: none !important;
  }
  .mobileBox {
    display: block;
  }
}
@media (max-width: 600px) {
  .filter > div {
    width: 85vw;
  }
  .filter {
    margin-left: calc((100vw - 85vw) / 2);
  }
  .filterForm {
    padding: 25px;
    border-radius: 0;
    max-height: 70vh;
    overflow-y: auto;
  }
  .allNotes {
    display: none !important;
  }
  .mobileBox {
    display: block;
  }
  .modalBox {
    width: 90% !important;
  }
}

.modalBox {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  outline: 0;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px,
    rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  padding: 40px;
}

.modalButtonRow {
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
}
.modalButtonRow > button {
  width: 152px;
}
.modalButtonRow.fitTight > button {
  width: 50%;
}
@media screen and (max-width: 600px) {
  .modalButtonRow.fitTight > button {
    width: 100%;
  }
}

.modalLink {
    text-decoration: none;
    color: var(--colors-slate);
}